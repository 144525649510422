$secondary-color: #626262;

.page {
  height: 100dvh;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
}

.progressContainer {
  flex: 1;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.content {
  height: 100%;
  position: absolute;
  display: flex;
  // overflow: hidden;
  transition: 0.4s;
}
.step {
  min-width: 100vw;
  max-width: 100vw;
  height: 100%;
  overflow: auto;
  padding: 15px;
}

.animationImg {
  border-radius: 15px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.035);
  margin-bottom: 25px;
}

.chatsImgContainer {
  margin-bottom: 25px;
  .animationImg {
    max-width: 65%;
    margin-bottom: 0;
  }
}

.explanationTitle {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.explanationSubtitle {
  text-align: center;
  line-height: 1.7;
  color: $secondary-color;
}
.explanationContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.ctaBtn {
  padding: 25px 50px;
  font-size: 16px;
  font-weight: 400;
  margin: 15px 15px 40px 15px;
  transition: 0.3s;
  z-index: 1;
}

.oAuthContainer {
  @extend .ctaBtn;
  border: thin solid #a6a6a638;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// PRESET PROFILE CMP!!@@#$#@@!!

.presetProfiles {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.profile {
  border-radius: 15px;
  border: thin solid #a6a6a638;
  padding: 15px;
  transition: 0.3s;
  &.selected {
    background-color: #e0e0e038;
    border-color: black;
  }
}

.profileHeader {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.profileHeaderInfo {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profileName {
  font-size: 20px;
  font-weight: 500;
}
.profileAdditionalInfo {
  font-size: 14px;
  font-style: italic;
  font-weight: 200;
  color: $secondary-color;
}

.profileDescription {
  font-size: 14px;
  color: $secondary-color;
  margin-bottom: 10px;
  line-height: 1.5;
  font-weight: 300;
}

.profilePicksContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  span {
    font-size: 14px;
    font-weight: 500;
  }
  .picksList {
    display: flex;
    gap: 10px;
    overflow: auto;
    padding: 10px 0px;
    .pickImg {
      border-radius: 15px;
      object-fit: cover;
      max-width: 100px;
      aspect-ratio: 4/7;
      box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06),
        0px 1px 2px rgba(0, 0, 0, 0.035);
    }
  }
}

// END PROFILES!!!!!!!@#!@#@$@#$@$#@!@#
