.summaryLookalike {
  position: fixed;
  top: 100%;
  left: 0;
  padding: 24px;
  img {
    max-width: 100px;
    object-fit: contain;
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  &.inView {
    animation-name: goDown;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }
  &.pinned {
    animation-name: goUp;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }
}

@keyframes goUp {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes goDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}

.detectionIndicator {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  z-index: 2;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
    animation: ripple 2s infinite;
  }

  &::after {
    animation-delay: 0.5s;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

.scanningWave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 48%,
    rgba(255, 255, 255, 0.9) 50%,
    rgba(255, 255, 255, 0.4) 52%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1s infinite linear;
  transform: skewX(-20deg);
  pointer-events: none;
}

@keyframes shimmer {
  0% {
    transform: translateX(-150%) skewX(-20deg);
  }
  100% {
    transform: translateX(150%) skewX(-20deg);
  }
}

.dotGrid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  z-index: 2;

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 0.8) 0%,
      rgba(255, 255, 255, 0.8) 15%,
      transparent 20%
    );
    background-size: 3.33% 3.33%;
    background-position: center;
    pointer-events: none;
    animation: dotPulse 1s linear infinite;
    background-blend-mode: screen;
  }
}

.resultsActionsContainer {
  padding: 0px;
}

@keyframes dotPulse {
  0% {
    filter: brightness(0.1);
  }
  50% {
    filter: brightness(1);
  }
  100% {
    filter: brightness(0.1);
  }
}
