.page {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  overflow-y: auto;
  justify-content: center;
  align-items: baseline;
  img {
    max-width: 200px;
    border-radius: 16px;
  }
}
