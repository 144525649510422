@import "/src/styles/index.scss";

.label {
  font-size: 13px;
}

.onboardingInput {
  background-color: white;
  height: 52px;
  border: thin solid #00000010;
  &::placeholder {
    color: #00000010;
  }
}

.form {
  gap: 30px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.select {
  padding: 10px;
  border-radius: 5px;
  display: flex;
  border: thin solid aliceblue;
  font-size: 13px;
}

.optionsBody {
  flex: 1;
  overflow: auto;
  font-size: 13px;
}
.field {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  text-align: center;
  text-transform: capitalize;
  font-size: larger;
  font-size: 400;
}
