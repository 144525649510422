.main-container {
  // overflow: auto;
  // flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr;
  flex: 1;
  // height: 100%;
  > * {
    grid-column: 2;

    &.full {
      grid-column: 1 / -1;
    }
  }

  @include for-mobile-layout {
    grid-template-columns:
      rem(0px)
      minmax(auto, $mobile-breakpoint)
      rem(0px);
  }
  @include for-narrow-layout {
    grid-template-columns:
      rem(20px)
      minmax(auto, $narrow-breakpoint)
      rem(20px);
  }
  @include for-normal-layout {
    grid-template-columns:
      rem(20px)
      minmax(auto, $normal-breakpoint)
      rem(20px);
  }
  @include for-wide-layout {
    grid-template-columns:
      rem(40px)
      minmax(auto, $wide-breakpoint)
      rem(40px);
  }
  @include for-xl-wide-layout {
    grid-template-columns:
      rem(60px)
      minmax(auto, 1fr)
      rem(60px);
  }
}
.main-app-wrapper {
  display: flex;
  flex-grow: 1;
}
