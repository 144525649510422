.title {
  font-size: 28px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
  margin-top: 16px;
}
.subtitle {
  line-height: 1.5;

  font-weight: 500;
  text-align: center;
}

.celebs {
  display: grid;
  padding: 5px;
  grid-template-columns: 3fr 2fr 3fr;
  grid-template-rows: 2fr 2fr 3fr 7fr;
  gap: 8px;
  justify-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
  img {
    border-radius: 16px;
    min-width: 100%;
    object-fit: cover;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  }
}

.search {
  font-size: 14px;
}

.blinker {
  border-left: 4px solid #1f2029;
  height: 100%;
  display: flex;
  animation: blink;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  height: 20px;
  &.show {
    visibility: visible;
    animation: unset;
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.productContainer {
  animation-name: show;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: 110px;
  img {
    min-width: 100%;
    object-fit: cover;
    border-radius: 16px;
    animation-duration: 8s;
    animation-iteration-count: infinite;
    // transform: translate(10%, 10%);
    animation-fill-mode: both;
    animation-direction: alternate;
    display: block;
  }
}
.move1 {
  animation-name: movea;
}
.move2 {
  animation-name: moveb;
}
.move3 {
  animation-name: movec;
}

.product {
  position: relative;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  // transform: translate(10%, 10%);
  animation-fill-mode: both;
  animation-direction: alternate;
  // animation-name: move;
  display: flex;

  .overlayContent {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    padding: 30px 10px 10px 10px;

    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.4)
    );
  }
}

@keyframes movea {
  from {
    transform: translate(10%, 10%);
  }
  to {
    transform: translate(-10%, -10%);
  }
}
@keyframes moveb {
  from {
    transform: translate(10%, -2%);
  }
  to {
    transform: translate(10%, -10%);
  }
}

@keyframes movec {
  from {
    transform: translate(5%, -8%);
  }
  to {
    transform: translate(-9%, 10%);
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
