.avatar {
  min-width: 34px;
  max-width: 34px;
  min-height: 34px;
  max-height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  border-radius: 50%;
  overflow: hidden;
  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
  }
  &.initials {
    font-weight: 600;
    background-color: #191a1b;
    font-size: 14px;
    text-transform: capitalize;
  }
}
