.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    justify-content: center;
    margin-top: 30px;
    .select{
        width: 300px;
    }
}