/* StyleSelectionInfo.css */

.style-selection-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.style-card {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.collage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  height: auto;
}

.collage-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  pointer-events: none;
  border-radius: 0 0 16px 16px;
  z-index: 10;
}

.card-overlay h3 {
  margin: 0;
  font-size: 18px;
}

.card-overlay p {
  margin: 5px 0 0;
  font-size: 14px;
}

.action-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
}

.action-buttons button {
  background: none;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 8px;
  transition: background 0.3s;
}

.action-buttons .dislike {
  color: #f44336;
}

.action-buttons .superlike {
  color: #2196f3;
}

.action-buttons .like {
  color: #4caf50;
}

.action-buttons button:hover {
  background: rgba(0, 0, 0, 0.1);
}

/* TINFER */

.card-container {
  position: absolute;
  width: 100%;
  top: 0;
}

.card {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
}

.card-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);
  color: white;
}

.card-info h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.card-info p {
  font-size: 0.875rem;
  opacity: 0.9;
  margin: 0.25rem 0 0;
}

/* Indicators */
.indicator-icon {
  border-radius: 9999px;
  padding: 0.5rem;
}

.indicator-icon .icon {
  width: 2rem;
  height: 2rem;
  color: white;
}

.indicator-icon-nope {
  background-color: rgb(239, 68, 68);
}

.indicator-icon-like {
  background-color: rgb(34, 197, 94);
}

.indicator-icon-superlike {
  background-color: rgb(59, 130, 246);
}

/* Indicator Positions */
.indicator-left {
  position: absolute;
  top: 2rem;
  left: 2rem;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, transform 0.2s;
}

.indicator-right {
  position: absolute;
  top: 2rem;
  right: 2rem;
  opacity: 0;
  transform: scale(0);
  transition: opacity 0.2s, transform 0.2s;
}

.indicator-up {
  position: absolute;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%) scale(0);
  opacity: 0;
  transition: opacity 0.2s, transform 0.2s;
}

/* Text Indicators */
.text-indicator {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s;
}

.text-indicator-left {
  left: 2rem;
  top: 2rem;
}

.text-indicator-right {
  right: 2rem;
  top: 2rem;
}

.text-indicator-up {
  left: 50%;
  top: 2rem;
  transform: translateX(-50%) scale(0);
}

.indicator-text {
  font-size: 2rem;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-width: 4px;
  border-style: solid;
  border-radius: 0.75rem;
  letter-spacing: 0.05em;
  display: block;
  text-shadow: 0px 0px 8px rgba(255, 255, 255, 0.5);
}

.indicator-text-nope {
  color: rgb(239, 68, 68);
  border-color: rgb(239, 68, 68);
  transform: rotate(-24deg);
}

.indicator-text-like {
  color: rgb(34, 197, 94);
  border-color: rgb(34, 197, 94);
  transform: rotate(24deg);
}

.indicator-text-superlike {
  color: rgb(59, 130, 246);
  border-color: rgb(59, 130, 246);
  text-align: center;
}

/* Visible state */
.visible {
  opacity: 1;
  transform: scale(1);
}

.text-indicator-up.visible {
  transform: translateX(-50%) scale(1);
}

.action-buttons > div {
  border-radius: 50%;
  background-color: white;
  border: thin solid #00000010;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Adjustments for Safari compatibility */

.style-card {
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.collage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;
  height: auto;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  pointer-events: none;
  border-radius: 0 0 16px 16px;
  z-index: 10;
}
