.backdrop {
  position: fixed;
  width: 0;
  height: 0;
  z-index: 10;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: rgba(84, 84, 84, 0.4);
  &.open {
    animation-name: opacity;
    animation-delay: 0.05s;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    width: 100dvw;
    height: 100dvh;
  }
}
.container {
  z-index: 100;
  padding-top: 40px;
  position: fixed;
  // background-color: #000000;
  background-color: #ffffff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  opacity: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  // border: thin solid #dbdbdbd3;

  &.left {
    top: 0;
    right: 100%;
    &.closing {
      animation-name: goRight;
      animation-fill-mode: forwards;
      animation-direction: reverse;
      animation-duration: 0.3s;
    }
    &.open {
      animation-delay: 0.1s;
      animation-duration: 0.3s;
      animation-name: goRight;
      animation-fill-mode: forwards;
    }
  }
  &.right {
    top: 0;
    left: 100%;

    &.closing {
      animation-name: goLeft;
      animation-fill-mode: forwards;
      animation-direction: reverse;
      animation-duration: 0.3s;
    }
    &.open {
      animation-delay: 0.1s;
      animation-duration: 0.3s;
      animation-name: goLeft;
      animation-fill-mode: forwards;
    }
  }
  &.bottom {
    top: 100dvh;
    left: 0;
    transform: translateY(0);
    transition: 0.3s;
    opacity: 0;
    // animation-fill-mode: forwards;
    // animation-name: disappear;
    // animation-delay: 1s;

    &.closing {
      animation: goDown;
      animation-fill-mode: forwards;
      animation-duration: 0.5s;
      opacity: 0;
      animation-timing-function: ease-in-out;
    }
    &.open {
      opacity: 1;
      // display: flex;
      animation: goUp;
      animation-fill-mode: forwards;
      animation-duration: 0.4s;
      // animation-timing-function: ease-in-out;
      // transform: translateY(-100%);
    }
  }
  &.top {
    top: -100&;
    left: 0;
    &.open {
      transform: translateY(100%);
    }
  }
}

@keyframes disappear {
  from {
    display: flex;
  }
  to {
    display: none;
  }
}
@keyframes goUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

@keyframes goDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes goRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes goLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes identifier {
}

.title {
  padding: 16px;
  display: flex;
  align-items: center;
  font-weight: 600;
  width: 100%;
  background-color: #ffffff;
  font-size: 14px;
  border-bottom: thin solid #eaeaeaa8;
  margin-bottom: 16px;
  .back {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

.swipeableTitle {
  text-align: center;
  padding: 10px 10px 20px 10px;
}

.swipeableSignal {
  position: absolute;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.floatingBack {
  position: fixed;
  top: 16px;
  &.app {
    top: 58px;
  }
  left: 16px;
  z-index: 100;
  width: 40px;
  height: 40px;
  background-color: #ffffffbf;
  backdrop-filter: blur(30px);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.appSpace {
  &.app {
    padding-top: 45px;
  }
}
