.ctaBtn {
  padding: 25px 50px;
  font-size: 16px;
  font-weight: 400;
  margin: 15px 15px 40px 15px;
  transition: 0.3s;
}
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}
