.option {
  border-bottom: thin solid #d3d3d338;
  padding: 16px 0px;
  display: flex;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  .icon {
    width: 20px;
    height: 20px;
    path {
      fill: #1f2029;
    }
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  .icon {
    display: flex;
    font-size: 20px;
  }
}

.searchOptions {
  padding: 0px 16px;
  overflow: auto;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
}
