.formContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  transition: 0.3s;
  min-width: 100%;
  max-width: 100%;
}

.formBody {
  @extend .formContainer;
  padding: 0px 16px 16px 16px;
  &.naked {
    padding: 0px;
  }
}

.slider {
  transition: 0.25s;
  display: flex;
  height: 100%;
}
