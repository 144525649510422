@import "src/styles/index";

.msgContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px;
  padding: 16px 16px;

  .msg {
    font-size: 14px;
    line-height: 1.4;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;
    animation-name: pop;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
  }
  &:not(.user) {
    justify-content: flex-start;
    .msg {
      margin-bottom: 10px;
      // background-color: #2d2d2d;
      background-color: #efefef;
      max-width: 60%;
      &:before {
        left: -7px;
        width: 20px;
        // background-color: #2d2d2d;
        background-color: #efefef;

        border-bottom-right-radius: 16px 14px;
      }

      &:after {
        left: -9px;
        width: 9px;
        height: 24px;
        // background-color: #000000;
        background-color: #ffffff;
        border-bottom-right-radius: 10px;
      }
    }
  }
  &.user {
    padding-right: 4px;
    justify-content: flex-end;

    .msg {
      background-color: #405de6;
      color: #ffffff;
      font-weight: 400;
      max-width: 75%;
      &:before {
        right: -7px;
        width: 20px;
        background-color: #405de6;
        border-bottom-left-radius: 16px 14px;
      }
      &:after {
        right: -26px;
        width: 26px;
        // background-color: #000000;
        background-color: #ffffff;

        border-bottom-left-radius: 10px;
      }
    }
  }
}
.productsMsgContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  gap: 15px;
  z-index: 0;
}

.searchOption {
  background-color: #fff;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 10px;
}

.userProductQuery {
  width: 160px;
}

.chatProductContainer {
  animation-name: show;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.4s;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.chatProduct {
  border-radius: 3px;
  min-width: 200px;
  max-width: 200px;
}

.container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  transition: 0.2s;
}

.container {
  padding-bottom: 150px;
  //
  background-color: #ffffff;

  .swiper {
    // background-color: #000000;
    background-color: #ffffff;
  }
}
.msgContainer {
  // background-color: #000000;
  background-color: #ffffff;

  border: none;
}
.productsContainer {
  display: flex;
  flex-wrap: nowrap;
  padding: 0px 8px;
  gap: 8px;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  justify-content: unset;
  &::-webkit-scrollbar {
    display: none;
  }
  .chatProduct {
    min-width: 120px;
    max-width: 160px;
    // padding: 0 5px;
  }
}
.chatSenderContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  .chatContent {
    max-width: 70%;
    width: fit-content;
    font-size: 14px;
    line-height: 1.5;
  }
}

.msgContainer {
  padding: 16px 0px;
  border-bottom: none;
}

.feature {
  font-weight: 600;
  text-transform: capitalize;
}

.debugModal {
  white-space: wrap;
  color: #2d2d2d;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include for-normal-layout {
    display: grid;
    grid-template-columns: auto 1fr;
  }
}

// .productsContainer {
//   display: flex;
//   flex-wrap: nowrap;
//   padding: 0;
//   gap: 10px;
//   .chatProduct {
//     min-width: 200px;
//     max-width: 200px;
//     // padding: 0 5px;
//   }
// }

@media screen and (min-width: 500px) {
  .msgContainer {
    padding: 16px;
  }
  .productsContainer {
    gap: 16px;
  }
}

@keyframes pop {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.01, 1);
  }
  100% {
    transform: scale(1);
  }
}

.populateSearch {
  padding: 0px 8px;
  display: flex;
}
.productCard {
  max-width: 140px;
}
