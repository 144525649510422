
.cards{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    .card{
        padding: 8px;
        border-radius: 3px;
        text-align: center;
        cursor: pointer;
        border: thin solid transparent;
        &.selected{
            border:thin solid #e13d13;
            
        }
    }
    img{
        aspect-ratio: 2/3;
        width: 250px;
    }
}
.prompt{
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
}

.container{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 25px;
}

.other{
    font-size: 20px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
}