.page {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 16px;
}

.listsContainer {
  gap: 50px;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  align-items: center;
  .middleBalancer,
  .iconContainer {
    flex: 1;
  }
  .iconContainer {
    display: flex;
    justify-content: flex-end;
    font-size: 30px;
  }
}
.subTitle {
  color: #797979;
  font-weight: 300;
  text-align: center;
  text-align: start;
}

.listRecs {
  font-size: 14px;
  text-decoration: underline;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #797979;
  path {
    fill: #797979;
  }
}
