.prices {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  flex: 1;
  .price {
    min-width: 40px;
    max-width: 40px;
    font-size: 14px;
    font-weight: 200;
    display: flex;
    justify-content: center;
  }
}

.values {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  overflow-x: auto;
  padding: 0 25px;
}
