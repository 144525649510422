.section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .sectionTitle {
    font-size: 14px;
    font-weight: 600;
  }
  .items {
    display: flex;
    flex-direction: column;
    border: thin solid #00000010;
    border-radius: 6px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-transform: capitalize;
    gap: 16px;
    padding: 16px;
    font-size: 14px;
    &:not(:last-of-type) {
      border-bottom: thin solid #00000008;
    }
  }
}

.delete {
  color: #df2c2c;
}
