/* Breakpoint mixins */

@mixin for-mobile-layout {
  @media (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin for-narrow-layout {
  @media (min-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin up-to-normal-layout {
  @media (max-width: $normal-breakpoint) {
    @content;
  }
}

@mixin for-normal-layout {
  @media (min-width: $narrow-breakpoint) {
    @content;
  }
}

@mixin for-wide-layout {
  @media (min-width: $normal-breakpoint) {
    @content;
  }
}
@mixin for-xl-wide-layout {
  @media (min-width: $wide-breakpoint) {
    @content;
  }
}

/* Media  Mixins */
@mixin aspect-ratio($width, $height) {
  position: relative;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > * {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  > img {
    height: 100%;
    object-fit: cover;
  }
}

// Mixin for removing hover efects on iphone screen
@mixin hover-supported {
  @media not all and (pointer: coarse) {
    &:hover {
      @content;
    }
  }
}

@mixin brd1 {
  border: 1px solid $brd1;
}
@mixin btn1 {
  border: none;
  border-radius: 1.5rem;
  color: #ffffff;
  font-weight: 500;
  cursor: pointer;
  line-height: 18px;
}

@mixin title1 {
  align-self: center;
  color: #4a4a4a;
  font-size: rem(18px);
}
@mixin subtitle1 {
  font-size: rem(12px);
  font-weight: 100;
  color: #6c7378;
}

@mixin iconMd {
  height: rem(20px);
  line-height: rem(20px);
  width: rem(20px);
}
@mixin centerAllInside {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
