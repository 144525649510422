@import "/src/styles/index.scss";

.sidePadding {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.filterContainer {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
  overflow: auto;
  .features {
    gap: 40px;
    display: flex;
    flex-direction: column;
    padding-bottom: 96px;
  }
  .section {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .filterBlock {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    border-bottom: thin solid rgba($color: #000000, $alpha: 0.1);

    .category {
      font-weight: 600;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 16px;
      @extend .sidePadding;
    }

    $count-min-height: 20px;
    .subCategory {
      font-weight: 500;
      font-size: 14px;
      text-transform: capitalize;
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .count {
        border-radius: 50%;
        background-color: #1f2029;
        min-height: $count-min-height;
        max-height: $count-min-height;
        min-width: $count-min-height;
        max-width: $count-min-height;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        font-weight: 600;
        visibility: hidden;
        &.show {
          visibility: visible;
        }
      }
      .icon {
        font-size: 18px;
        color: #1f2029;
        transition: 0.25s;
        &.open {
          transform: rotate(90deg);
        }
      }
    }
  }
}
.footer {
  padding: 10px;
  padding-bottom: 25px;
  background-color: #ffffff;
  // background-color: #000000;
  // border-top: thin solid #5f5e5e5c;
  display: flex;
  flex-direction: column;
}

.values {
  @extend .sidePadding;
  display: flex;
  gap: 10px;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  transition: 0.25s;
  max-height: 0px;
  min-height: 0px;
  &.open {
    max-height: 500px;
  }
}

.colorValue {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  &.multi {
    background: linear-gradient(
      45deg,
      rgba(195, 34, 34, 1) 0%,
      rgba(253, 45, 240, 1) 23%,
      rgba(64, 45, 253, 1) 37%,
      rgba(45, 253, 144, 1) 59%,
      rgba(198, 253, 45, 1) 83%
    );
  }
  &.selected {
    border-color: white;
  }
}
