@import "src/styles/index";

.chatInput {
  height: 48px;
  border-radius: 12px;
  padding: 0 8px 0 12px;
}
.input {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  min-width: 70%;
  align-self: center;
}

@include up-to-normal-layout {
  .chatInput {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    background: white;
    height: 48px;
    color: #191a1b;
    border: thin solid rgba($color: #000000, $alpha: 0.1);
  }
  .options {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    padding: 12px 16px;
    .optionLabel {
      animation-name: slideInFromRight;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
  }
  .input {
    padding-bottom: 0px;
    min-width: 100%;
    max-width: 100%;
    background: white;
    border-top: thin solid #ececf0;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    flex-direction: column;
    .footer {
      background-color: #fff;
      padding: 10px;
      width: 100%;
      transition: 0.1s;
    }
    form {
      padding: 0 16px;
      width: 100%;
      max-width: 750px;
    }
    &.app {
      .footer {
        padding: 15px;
      }
      transition: 0.15s;
      &.focused {
        .footer {
          padding: 5px;
        }
      }
    }
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
