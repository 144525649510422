.listContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
  }
}

.thumbnailsContainer {
  display: flex;
  gap: 3px;

  .imgThumbnailContainer {
    flex: 1;
    aspect-ratio: 5/9;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
    }
    &:last-of-type {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
    &:first-of-type {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    .placeholder {
      background-color: #f0f0f0;
      height: 100%;
      flex: 1;
    }
  }
}
