.weight {
  display: flex;
  gap: 16px;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
}

.preview {
  display: flex;
  gap: 8px;
  min-height: 200px;
  max-height: 200px;
  overflow: auto;
}

.footer {
  position: absolute;
  bottom: 24px;
  padding: 16px;
  display: flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  left: 0;
  padding: 10px;
}

.detailsWeightsContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 1;
  padding: 0px 16px 16px 16px;
  justify-content: flex-start;
}

.injectedPreviewProduct {
  min-width: 100px;
}
