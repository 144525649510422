$length: 60px;

.wrapperColors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  flex: 1;
  gap: 12px;
  padding: 5px 0;
}

.colorValue {
  min-width: $length;
  max-width: $length;
  min-height: $length;
  max-height: $length;
  border-radius: 5px;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  padding: 5px;
  &.multi {
    background: linear-gradient(
      45deg,
      rgba(195, 34, 34, 1) 0%,
      rgba(253, 45, 240, 1) 23%,
      rgba(64, 45, 253, 1) 37%,
      rgba(45, 253, 144, 1) 59%,
      rgba(198, 253, 45, 1) 83%
    );
  }
  &.selected {
    border-color: black;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  .colorName {
    font-size: 10px;
    text-align: center;
    font-weight: 300;
    text-transform: capitalize;
    white-space: wrap;
  }
}
