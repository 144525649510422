.logo{
  cursor: pointer;
  font-size: 25px;
  font-weight: 600;
  color: white;
  background-color: black;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.placeholder{
    font-size: 16px;
    height: 25px;
    width: 25px;
  }
}