@import "/src/styles/index.scss";

.headerContainer {
  position: fixed;
  width: 100%;
  top: 25px;
  left: 0;
  display: flex;
  padding: 0 20px;
  justify-content: center;
  font-size: 14px;
  z-index: 100;
  &.mobileApp {
    top: 70px;
  }
  .header {
    width: 100%;
    max-width: 1000px;
    flex: 1;
    padding: 5px 10px;
    border-radius: 16px;
    overflow: hidden;
    background-color: #ffffff89;
    backdrop-filter: blur(30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .headerCTA {
    background-color: black;
    color: white;
    border-radius: 15px;
    padding: 15px 20px;
  }
}

.pageContainer {
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}
.actionBtns {
  display: flex;

  gap: 5px;
  justify-content: center;
  align-items: center;
  .pics {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
}
.actionBtn {
  background-color: black;
  color: white;
  border-radius: 15px;
  padding: 15px 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  &.secondary {
    color: black;
    background-color: transparent;
    font-size: 14px;
  }
}

.homeOpener {
  scroll-snap-align: start;
  height: 100dvh;
  // justify-content: center;
  flex: 1;
  // flex-direction: column;
  // align-items: center;
  // display: flex;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.bgVideo {
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;

  top: 0;
  left: 0;
  object-fit: cover;
}
.textContainer {
  width: 100%;
  position: absolute;
  top: 0;
  word-wrap: none;
  display: flex;
  flex-direction: column;
  color: #2d2d2d;
  // min-width: 800px;
  min-height: 800px;
  gap: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 30%;
  z-index: 10;
  // filter: blur(41.5px);
  background-image: radial-gradient(
    circle closest-side,
    white 30%,
    rgba(255, 255, 255, 0.95) 55%,
    rgba(255, 255, 255, 0.92) 65%,
    rgba(255, 255, 255, 0.87) 70%,
    rgba(255, 255, 255, 0.83) 75%,
    rgba(255, 255, 255, 0.77) 81%,
    rgba(255, 255, 255, 0.7) 84%,
    transparent 100%
  );
  // rgba(255, 255, 255, 0.65) 77%,
  // rgba(255, 255, 255, 0.6) 80%,
}
.title {
  max-width: 95vw;
  font-size: 28px;
  display: flex;
  justify-content: center;
  font-weight: 800;
  text-align: center;
  line-height: 1.6;
}
.subtitle {
  max-width: 40ch;
  max-width: 95vw;

  text-align: center;
  line-height: 1.6;
  font-weight: 400;
  color: #20202070;
  font-size: 14px;
  line-break: str-length($string: "");
  margin-bottom: 0px;
}
.bottomContent {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 60px;
}

.sectionContainer {
  scroll-snap-align: start;
  width: 100dvw;
  justify-content: center;
  display: flex;

  .section {
    display: flex;
    margin: 0px 16px;
    padding-top: 72px;
    flex-direction: column;
    align-items: center;
    height: 100dvh;
    max-width: 100vw;
    width: 100vw;
    overflow: hidden;
    opacity: 0;
    transition: 0.3s;
    h2 {
      font-size: 20px;
      text-align: center;
      font-weight: 500;
    }
    &.open {
      animation-name: show;
      animation-delay: 0.1s;
      animation-duration: 0.6s;
      animation-fill-mode: forwards;
    }
    .sectionTitle {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }
    p {
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;
      text-align: center;
      color: #7e7e7e;
    }
    // img {
    //   max-width: 400px;
    // }
  }
}

.products {
  animation-duration: 20s;
  animation-name: goRight;
  display: flex;
  align-items: center;
  gap: 15px;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  .imgCard {
    width: 150px;
    aspect-ratio: 4/7;
    object-position: top;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06),
      0px 1px 2px rgba(0, 0, 0, 0.035);
  }
  &:nth-child(1) {
    margin-inline-start: 60px;
  }
  &:nth-child(2) {
    animation-name: goLeft;
  }
}

@keyframes goRight {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0%);
  }
}
@keyframes goLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}

.sectionContainer {
  scroll-snap-align: start;
}

.chatContainer {
  background: linear-gradient(
    0deg,
    rgba(253, 252, 255, 0.7665660014005602) 0%,
    rgba(231, 231, 231, 1) 43%
  );
  flex: 1;
  padding: 1px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  .chat {
    border-radius: 16px;
    background-color: white;
    flex: 1;
    aspect-ratio: 9/10;

    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
    }
  }
}

.celebsLookalikesImgsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  align-items: center;
  max-width: 100vw;
}
.celebsMainImgContainer {
  display: flex;
  justify-content: center;
}

.celebsMainImg {
  border-radius: 16px;
  max-width: 200px;
}

.reviews {
  background-color: #171717;
  height: 100dvh;
  width: 100vw;
  padding: 72px 16px 0px 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, transparent, #171717);
  }
  h2 {
    font-size: 20px;
    font-weight: 500;
    color: white;
  }
  .reviewsContainer {
    position: relative;
    margin-top: 16px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .overlay {
      top: 0px;
      background: linear-gradient(to bottom, #171717, transparent);
      z-index: 1;
    }
  }
  .columns {
    padding-top: 50px;
    columns: 1;
    overflow: auto;
    flex: 1;
  }
  .singleReview {
    padding: 16px;
    background-color: #353535;
    color: white;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    break-inside: avoid;
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 16px;
    .user {
      display: flex;
      align-items: center;
      gap: 16px;
      .userInfo {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .info {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}

.joinView {
  position: fixed;
  top: 0;
  left: 0;
  height: 0px;
  width: 0px;
  background: #ffffffe4;
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  // transform: translate3d(0, 0, 0);
  display: flex;
  flex-direction: column;
  gap: 32px;
  transition: opacity 0.5s;
  z-index: 10000;
  opacity: 0;
  overflow: hidden;
  &.open {
    opacity: 1;
    width: 100vw;
    height: 100vh;
  }
}

@include for-normal-layout {
  @keyframes goRight {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: translateX(0%);
    }
  }
  @keyframes goLeft {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(-50%);
    }
  }

  .actionBtn {
    font-size: 20px;
    padding: 20px 50px;
    border-radius: 50px;
    &.secondary {
      font-size: 18px;
    }
  }

  .homeOpener {
    scroll-snap-align: start;

    .textContainer {
      min-width: 760px;
      min-height: 100%;
    }
    .title {
      font-size: 58px;
      font-weight: 600;
      line-height: 1.5;
    }
    .bottomContent {
      .subtitle {
        font-size: 20px;
      }
    }
  }
  .sectionContainer {
    .section {
      padding: 0px 40px;
      padding-top: 120px;
      max-width: 1000px;
      h2 {
        font-size: 36px;
        font-weight: 300;
      }

      p {
        font-size: 16px;
      }
    }
  }
  .chatContainer {
    flex: none;
    width: unset;
    .chat {
      height: 700px;
      padding: 16px;
      max-height: 700px;
    }
  }
  .searchStep {
    padding: 0px 60px;
  }
  .overrideProductSearchContext {
    width: 150px !important;
    max-width: 150px !important;
    align-items: flex-start;
  }

  .overrideSearchPhrase {
    // HAVE NO IDEA WHY IT IS NOT OVERRIDING THE CLASSNAME
    font-size: 32px !important;
    font-weight: 100;
  }
  .blinkerOverride {
    border-left-width: 2px !important;
    height: 32px !important;
  }
  .celebsLookalikesImgsContainer {
    gap: 58px;
    flex-direction: row;
    flex: unset;
  }
  .celebsMainImgContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .celebsMainImg {
    max-width: 400px;
  }

  .reviews {
    h2 {
      font-size: 36px;
      font-weight: 300;
    }
    padding: 120px 40px 0px 40px;

    .columns {
      columns: 4;
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    transform: translate(0px);
    opacity: 1;
  }
}
.products {
  animation-duration: 30s;
  gap: 50px;
  .imgCard {
    width: 220px;
  }
}

// V2

.titleV2 {
}
