.container {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding: 16px;
  padding-top: 0;
  .brand {
    overflow: hidden;
    animation-name: show;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    opacity: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    // align-items: center;
  }
  .titleHeader {
    font-size: 14px;
    font-weight: 600;
    // text-decoration: underline;
    text-transform: uppercase;
  }
}
.preview {
  border-radius: 6px;
  overflow: hidden;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
