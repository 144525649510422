.chatSnippet{
    display: flex;
    align-items: center;
    width: 100%;
    
    .content{
        display: flex;
        flex: 1;
        align-items: flex-start;
        word-wrap: normal;
        white-space: break-spaces;
        line-height: 1.4;
        gap: 8px;
    }
    margin-bottom: 15px;
        padding:15px;
        border-radius: 10px;
    background-color: #282828;
    .jenny{
        text-decoration: underline;
    }
    .openChat{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
    }
}