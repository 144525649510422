.title {
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 24px;
  margin: 16px 0;
}

.option {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: thin solid #ececec;
  font-size: 14px;
  font-weight: 400;
  border-radius: 50px;
  transition: 0.3s;
  &.selected {
    border-color: #1f2029;
  }
}

.header {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  display: none;
  &.stickeyFooter {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 32px;
    width: 100%;
    left: 0;
    padding: 16px;
    z-index: 1;
    animation-name: goUp;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }
}
@keyframes goUp {
  from {
    transform: translateY(50%);
  }
  to {
    transform: translateY(0%);
  }
}
