.loader {
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-bottom: 20%;
  .text {
    font-weight: 600;
    text-transform: uppercase;
    color: #191a1b;
  }
}
