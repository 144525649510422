.ctaBtn {
  padding: 25px 50px;
  font-size: 16px;
  font-weight: 400;
  margin: 15px 15px 40px 15px;
  transition: 0.3s;
  z-index: 1;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  overflow: hidden;
}

.stepContent {
  gap: 32px;
  transition: transform 0.8s ease, opacity 0.8s ease;
  opacity: 0;
  transform: translateY(500px);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  &.open {
    opacity: 1;
    transform: translateY(0px);
    &.close {
      opacity: 0;
      transform: translateY(-500px);
    }
  }
}

.error {
  font-size: 16px;
  font-weight: 500;
  color: var(--red-alert);
  display: flex;
  justify-content: center;
}
