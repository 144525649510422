@import "src/styles/index.scss";
$position-for-infinite-scroll-dont-delete: relative;
.results {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 24px 16px;
  display: grid;
  overflow: auto;
  padding-bottom: 80px;
  position: $position-for-infinite-scroll-dont-delete;
  .fetchingMore {
    position: absolute;
    bottom: 0;
  }
}

.mobileBrand {
  font-size: 18px;
  font-weight: 600;
}

@include for-normal-layout {
  .results {
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }
}

.overrideClassName {
  max-width: unset;
}
