@import "/src/styles/index.scss";

.container {
  width: 100%;
  position: relative;
}
.containerInp {
  min-height: 42px;
  border-radius: 8px;
  background-color: #f3f3f3;
  width: 100%;
  &.autoCompleteOpen {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.inputContainer {
  position: relative;
  width: 100%;
}
.autoComplete {
  width: 100%;
  position: absolute;
  // max-height: 300px;
  overflow: auto;
  left: 0;
  border: thin solid #989797;
  border-top: none;
  z-index: 10;
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  background: white;
  transition: 0.1s;
  animation-name: openPretty;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-duration: 0.1s;
}
// .autoCompleteList{
//     padding:  14px;

// }

// .autoCompleteItem{
// padding: 10px 15px;
// cursor: pointer;
// transition: .1s;
// &:hover,&.focused{
//     background-color: #f1efed
// }
// &:last-of-type{
//     border-bottom: none;
// }
// }

.clickHandler {
  top: 0;
  left: 0;
  position: fixed;
  width: 0px;
  height: 0px;
  background-color: black;
  transition: opacity 0.3s;
  opacity: 0;
  &.show {
    height: 100%;
    width: 100%;
    opacity: 0.2;
  }
}

@keyframes openPretty {
  0% {
    max-height: 0px;
    overflow: hidden;
  }
  100% {
    max-height: 300px;
    overflow: auto;
  }
}
@include for-normal-layout {
  .containerInp {
    input {
      color: #2d2d2d;
    }
  }
}

.icon {
  min-width: 20px;
  min-height: 20px;
  svg {
    path {
      fill: #767676;
    }
  }
  &.active {
    path {
      fill: #111111;
    }
  }
}
