.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(84px, 1fr));
  gap: 16px;
  padding: 16px;
  padding-bottom: 96px;
  .thumbnail {
    animation-name: show;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    opacity: 0;
  }
}
.feed {
  columns: 2;
  gap: 16px;
  padding: 16px;
  padding-bottom: 96px;
  img {
    animation-name: show;
    animation-fill-mode: forwards;
    animation-duration: 0.3s;
    opacity: 0;
    border-radius: 16px;
  }
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY(150%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
