@import "src/styles/index";

.detailsContainer {
  display: flex;
  gap: 30px;
  flex: 1;
  overflow: hidden;
  position: relative;
}

.icon {
  font-size: 20px;
}
.pageContainer {
  flex: 1;
  overflow: auto;
  animation-name: opacity;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  padding-bottom: 20px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 10px;
}
.metaContent {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 35px;
  gap: 40px;
}
.title {
  font-size: 16px;
  text-transform: capitalize;
}
.price {
  font-size: 16px;
  font-weight: bold;
}
.brand {
  font-weight: 600;
  font-size: 20px;
}

.variants {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .variant {
    .variantTitle {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .variantOptions {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      .variantOption {
        border: thin solid #989898;
        padding: 5px;
        .selected {
          border: thin solid blue;
        }
      }
    }
  }
}
.vendors {
  .vendor {
    img {
      width: 30px;
    }
    padding: 8px 0;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    transition: 0.2s;
    font-weight: 500;

    @include hover-supported {
      background-color: #ececec;
      .shop {
        transition: 0.2s;
        opacity: 1;
      }
    }
    .arrow {
      transition: 0.3s;
    }
    .vendorPrice {
      display: flex;
      font-size: 14px;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      gap: 10px;
    }
  }
}

.imgContainer {
  flex: 3;
  display: flex;
  gap: 15px;
  overflow: hidden;
  .thumbnailsList {
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
    .thumbnail {
      width: 100px;
      height: 100px;
    }
    .arrowUp {
    }
    .arrowDown {
    }
  }
  .mainImg {
    min-width: 400px;
    max-width: 800px;
    min-height: 400px;
    max-height: 550px;
    .arrowPrev {
    }
    .arrowNext {
    }
    flex: 1;
  }
}

.actionsBar {
  display: flex;
  gap: 10px;
  align-items: center;
}

.vendorComparison {
  text-transform: uppercase;
  font-weight: 500;
  // color: aliceblue;
  margin-bottom: 10px;
  font-size: 14px;
}

.singleVendorOfferFooter {
  position: absolute;
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  bottom: 0;
  left: 0;
  align-items: center;
  border-top: thin solid #d1d1d17d;
}

.singleVendorOffer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.singleVendorDetails {
  display: flex;
  align-items: center;
  gap: 5px;
  // color: #f0f8ffc4;
  font-size: 12px;
  font-weight: 400;
  justify-content: flex-end;
  color: #737373;
}

.allImgs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  justify-items: center;
}

.similarContainer {
  width: 100%;
  overflow: hidden;
  padding: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  z-index: 0;
  .similarLoading {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-wrap: nowrap;
    gap: 8px;
  }
  .similarProducts {
    flex: 1;
    overflow: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    .similar {
      display: flex;
      flex: 1;
      max-width: 180px;
    }
  }
}

@include up-to-normal-layout {
  .detailsContainer {
    flex-direction: column;
    gap: 5px;
  }
  .imgContainer {
    .mainImg {
      display: flex;
      justify-content: center;
    }
    .thumbnailsList {
      display: none;
    }
  }
  .contentContainer {
    padding: 10px;
  }
  .sizeSelector {
    display: flex;
    // background-color: white;
    justify-content: center;
    padding: 10px;
    font-weight: 600;
  }

  .actionsBar {
    display: none;
  }
  .singleVendorDetails {
    flex-direction: column;
    align-items: flex-start;
  }
}
@include up-to-normal-layout {
  .contentContainer {
    position: relative;
    width: 100%;
    gap: 8px;
    background-color: white;
    padding: unset;
  }
  .brand {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    font-weight: 400;
  }
  .price {
    font-weight: 600;
    font-size: 16px;
    &.prev {
      text-decoration: line-through;
    }
    &.current {
      color: #961c1c;
    }
  }
  .title {
    font-size: 14px;
  }

  .sizeBox {
    // background-color: #f2f3f5;
    // padding: 8px;
    // border-radius: 50px;
    span {
      font-size: 12px;
      min-width: 3ch;
      white-space: nowrap;
      // width: 2rem;
      display: flex;
      justify-content: center;
    }
  }

  .boxMessage {
    // background-color: #f9f7ee;
    // border-radius: 16px;
    // padding: 16px 8px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
  }
  .messageTitle {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    font-weight: 500;
  }

  .optionsWrap {
    display: flex;
    // flex-wrap: wrap;
    gap: 8px;
    path {
      fill: #000000;
    }
    overflow: auto;
  }
  .sidePadding {
    padding-inline-end: 16px;
    padding-inline-start: 16px;
  }

  // .price {
  //   font-weight: 600;
  // }

  .pageContainer {
    flex: 1;
    overflow: auto;
    height: 100%;
    &.app {
      padding-top: 45px;
    }
  }

  .mobileBrand {
    font-size: 14px;
    font-weight: 600;
  }
  .metaContent {
    min-height: unset;
    @extend .sidePadding;
    gap: 16px;
    border-bottom: thin solid #e9e9e9;
    padding-bottom: 30px;
  }
  .imgContainer {
    display: flex;
    gap: 5px;
    overflow: auto;
    .mainImg {
      // min-width: 100%
      min-width: 100vw;
      min-width: 100vw;
      display: flex;
      justify-content: center;
      transition: 0.2s;
      max-height: 400px;

      .img {
        width: 100%;
        transition: max-height 0.2s;
        max-height: 400px;
        // aspect-ratio: 4/5;
        object-fit: contain;
        /* min-width: unset; */
        // min-width: 100%;
        object-position: top;
        // border-radius: 15px;
      }
    }
  }
  .similarSection {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0px 16px;
  }
  .vendorName {
    font-size: 14px;
  }
  .singleVendorOffer {
    button {
      width: 100%;
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.similarTitle {
  font-size: 18px;
  font-weight: 500;
}

.likeButtonPosition {
  position: absolute;
  z-index: 1000;
  top: 0px;
  right: 20px;
  transform: translateY(-50%);
}

.likeButtonStyle {
  background-color: #ffffffbf;
  backdrop-filter: blur(30px);
  color: black;
  transition: 0.3s;

  justify-content: center;
  display: flex;
  align-items: center;
  // justifyContent: center;
  // gap: 8px;
  border-radius: 50px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

@include for-narrow-layout {
  .imgContainer {
    .mainImg {
      min-width: 55%;
      // min-width: unset;
      .img {
        max-height: 1000px;
      }
    }
    &.single {
      justify-content: center;
      .mainImg {
        max-width: 500px;
      }
    }
  }
}
