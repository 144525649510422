@use '/src/styles/index.scss' as *;


.step{
    flex: 1;
    background-color: #e5e2e2;
    height: 5px;
    border-radius: 10px;
    &.full{
        background-color: #50b5a5;
    }
}