.pinned {
  position: fixed;
  bottom: 32px;
  left: 0;
  padding: 8px 16px;
  z-index: 10;
  // background-color: white;
  width: 100%;
  // border-bottom: thin solid #e2e2e2;
  animation-name: goUp;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@keyframes goUp {
  from {
    transform: translateY(80%);
  }
  to {
    transform: translateY(0%);
  }
}
