@import "/src/styles/index.scss";
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // background-color: rgba(0, 0, 0, 0.497);
  background-color: #ffffff6e;
  z-index: 10;
  backdrop-filter: blur(15px);
  display: flex;
  // border-bottom: thin solid #e0e0e0bf;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06), 0px 1px 2px rgba(0, 0, 0, 0.035);

  flex-direction: column;
  .headerTitle {
    padding: 20px 25px 15px 25px;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .categories {
    // position: absolute;
    padding: 0 25px;
    height: 0px;
    overflow: hidden;
    display: flex;
    overflow: auto;
    align-items: center;
    gap: 10px;
    opacity: 0;
    transition: 0.25s;
    padding-bottom: 5px;
    text-transform: capitalize;
    &.show {
      height: 40px;
      opacity: 1;
    }
  }
}
.sections {
  display: flex;
  flex-direction: column;
  // gap: 60px;
  // overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  padding-top: 70px;

  .section {
    display: flex;
    flex-direction: column;
    border-bottom: 4px solid #f8f8f8a7;
    .separator {
      width: 3px;
      min-height: 100%;
      max-height: 100%;
      background-color: #c13584;
      border-radius: 15px;
    }

    .productList {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 15px 0;
      overflow: auto;
      .productCard {
        min-width: 250px;
        max-width: 250px;
      }
    }
  }
}

.container {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  h2 {
    text-align: center;
  }
}

@include up-to-normal-layout {
  .shopPage {
  }
  .results {
    grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
    gap: 1rem;
  }
  .sections {
    // gap: 50px;

    .section {
      padding-bottom: 50px;

      .productList {
        display: flex;
        padding-bottom: 10px;
        flex-wrap: wrap;
        align-items: center;

        .productCard {
          min-width: 180px;
          flex: 1;
        }
      }
      .buttonWrapper {
        display: flex;
        justify-content: center;
      }
    }
  }
  .title {
    font-size: 28px;
    font-family: "Playfair Display";
    text-transform: capitalize;
    text-align: center;
    font-weight: 500;
    padding-bottom: 2em;
    text-align: center;
    // justify-content: center;
    display: flex;
    justify-content: center;
  }
  .colelction {
    display: grid;
    padding: 0 40px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 5px;
    .main {
      grid-row: 1/3;
      grid-column: 1/3;
    }
    img {
      aspect-ratio: 5/9;
      border-radius: 15px;
      object-fit: cover;
    }
  }
  .input {
    outline: none;
    border: none;
    width: 100%;
    background-color: #e6e6e6;
    padding: 8px 12px;
    border-radius: 10px;
  }
  .inputContainer {
    display: flex;
    padding: 15px;
    position: sticky;
  }
}

.banner {
  display: flex;
  height: 100%;
  img {
    // width: 100%;
    display: block;
    aspect-ratio: 9/10;
  }
}

.types {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 15px;
  gap: 5px;
  div {
    border-radius: 15px;
    background-color: #f5f5f5a3;
    font-weight: 500;
    font-size: 14px;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
}

.gridFeedTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  text-align: center;
  padding-bottom: 40px;

  h2 {
    font-size: 24px;
    color: #303030;
  }
  .details {
    color: #757575;
    font-size: 14px;
  }
}
.intro {
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  align-self: center;
}

.chatIntro {
  padding: 40px 20px;
  background-color: #f5f5f5a3;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 15px;
  .chatIntroTitle {
    text-align: center;
    font-size: 22px;
    font-weight: 500;
  }
}

.brands {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  align-items: center;
  padding: 5px;
  .brand {
    padding: 30px;
    border-radius: 20px;
    white-space: nowrap;
    background-color: #f5f5f5a3;
    color: black;
    font-weight: 800;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.banner {
  width: 100%;
  aspect-ratio: 7/6;
  img {
    min-width: 100%;
    max-height: unset;

    object-fit: cover;
    object-position: top;
  }
}

.wrapper {
  padding: 0 8px;
}

.subtitle {
  font-size: 14px;
  color: #767676;
}

.sectionv2 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-weight: 500;
  font-size: 18px;
}

.feedCategories {
  display: flex;
  gap: 8px;
  overflow: auto;
  padding: 0px 0px 16px 16px;
  align-items: center;
  white-space: nowrap;
  background-color: white;
  // transition: padding 0.2s;
  z-index: 10;
  font-size: 14px;

  &.sticky {
    position: fixed;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    top: 0px;
    width: calc(100%);
    left: 0;
    padding: 0 15px;
    &.mobileApp {
      height: 100px;
      align-items: flex-end;
      .categoryv2 {
        padding-bottom: 15px;
        &::after {
          bottom: 6px;
        }
      }
    }
  }
  .categoryv2 {
    font-weight: 500;
    // padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      width: 100%;
      border-radius: 15px;
      background-color: transparent;
    }
    &.active {
      &::after {
        border-top: 3px solid black;
      }
    }
  }
}

.msg {
  font-size: 14px;
  line-height: 1.4;
  position: relative;
  padding: 10px 20px;
  border-radius: 25px;
  align-self: flex-end;
  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 24px;
  }

  background-color: #e5e4e4;
  color: #191919;
  font-weight: 400;
  &:before {
    right: -7px;
    width: 20px;
    background-color: #e5e4e4;
    border-bottom-left-radius: 16px 14px;
  }
  &:after {
    right: -26px;
    width: 26px;
    // background-color: #000000;
    background-color: #f9f9f9;

    border-bottom-left-radius: 10px;
  }
}

.pillContainer {
  animation-name: show;
  opacity: 0;
  animation-fill-mode: forwards;
  animation-duration: 0.3s;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateX(150%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
