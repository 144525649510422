.container {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  padding: 0px 10px 20px 10px;
}

.tabs {
  display: flex;
  align-items: center;
  padding: 10px;

  background-color: white;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;

    span {
      display: flex;
      flex-direction: column;
      gap: 4px;
      &::after {
        content: "";
        width: 100%;
        height: 4px;
        border-radius: 15px;
        background-color: transparent;
      }
    }
    &.selected {
      span {
        &::after {
          background-color: #1f2029;
        }
      }
    }
  }
}

.subTitle {
  color: #797979;
  font-size: 14px;
  text-align: center;
}
