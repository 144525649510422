.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  gap: 32px;
}

.title {
  text-align: center;
  text-transform: capitalize;
  font-size: larger;
  font-size: 400;
}

.brandContainer {
  flex: 1;
  overflow: auto;
}
.list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 6px;
}

.selectedList {
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: auto;
}

.search {
  color: aliceblue;
  background-color: transparent;
}

.selectedTitle {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}

.separator {
  border-top: thin solid rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
}

.selectedSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.label {
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 300;
}
