.menu {
  padding-top: 75px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-inline-end: thin solid #00000020;
  width: 250px;
}

.item {
  padding: 10px;
}
