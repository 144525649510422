.filtersContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  margin-bottom: 10px;
  min-height: 0px;
  max-height: 0px;
  transition: 0.3s;
  opacity: 0;
  &.showFilter {
    opacity: 1;
    min-height: 35px;
    max-height: 35px;
  }
}

.filterPreviewWrapper {
  padding: 20px 0 40px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.filterIconContainer {
  overflow: hidden;
  transition: 0.4s;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: fit-content;
  .filterIconWrapper {
    padding: 4px;
    border-radius: 50%;
    border: thin solid #cccccc;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  .title {
    font-weight: 500;
    text-transform: capitalize;
  }
  .clear {
    text-decoration: underline;
    color: grey;
    font-size: 14px;
  }
}
