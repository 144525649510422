.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.transition {
  gap: 32px;
  transition: transform 0.8s ease, opacity 0.8s ease;
  opacity: 0;
  transform: translateY(500px);
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  &.open {
    opacity: 1;
    transform: translateY(0px);

    &.close {
      opacity: 0;
      transform: translateY(-500px);
    }
  }
}
