.collectionsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
  padding: 16px;
  padding-top: 0px;
}
.categories {
  display: grid;
  gap: 48px;
  grid-template-columns: 1fr;
  align-content: flex-start;

  .category {
    display: flex;
    flex-direction: column;
    gap: 16px;
    img {
      object-fit: cover;
      border-radius: 6px;
      object-position: top;
      width: 100%;
      height: unset;
      aspect-ratio: 6/7;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
    }
  }
}
