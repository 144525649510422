.productGridContainer {
  flex: 1;
  overflow: auto;
}

.attrsGrid {
  display: grid;
  border-right: thin solid #00000020;
  border-top: thin solid #00000020;
  margin: 16px;
  & > div {
    border-inline-start: thin solid #00000020;
    border-bottom: thin solid #00000020;
    padding: 8px;
  }
}

.filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin: 8px;
}
