@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.img {
  transform: translateY(50px);
  animation: fadeInUp 0.5s ease-in-out forwards;
  opacity: 0;
  border-radius: 16px;
  margin-bottom: 1em;
  border: thin solid #80808042;
  object-fit: contain;
  min-height: 80px;
}
