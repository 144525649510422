@import "src/styles/index.scss";
// TODO : CLEAN THIS PAGE!!!!!!
.pageContainer {
  width: 100%;
  height: 100%;
  // min-height: 100%;
  // padding-top: 60px;
  // position: absolute;
  // phone experiment
  // left: 100%;
  // top: 0;
  //

  transition: 0.3s;
  border-bottom: none;
  display: flex;
  overflow: hidden;
  // background-color: #000000;
  // overflow: hidden;
  // z-index: 200;
  // &.open {
  //   animation-duration: 0.2s;
  //   animation-fill-mode: forwards;
  //   animation-timing-function: linear;
  //   animation-name: goUp;
  // }
  // &.close {
  //   animation-duration: 0.5s;
  //   animation-fill-mode: forwards;
  //   animation-timing-function: ease-in-out;
  //   animation-name: goDown;
  // }

  .swiper {
    padding: 15px;
    // background-color: #000000;
    display: flex;
    align-items: center;
    transition: 0.3s;
    cursor: pointer;
    min-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    border-bottom: thin solid rgba(204, 204, 204, 0.64);
    z-index: 10;
    background-color: #ffffff;
  }

  .title {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    gap: 10px;
    align-items: center;
  }
  .chatContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
  }
}

.domController {
  &.doNotInteract {
    * {
      display: none;
    }
  }
  &.disappear {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-name: delayDisappear;
    animation-delay: 1s;
  }
}

@keyframes goUp {
  from {
    visibility: hidden;
  }
  to {
    visibility: visible;
    transform: translateX(-100%);
  }
}

@keyframes goDown {
  from {
    visibility: visible;
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
    visibility: visible;
  }
}

@keyframes delayDisappear {
  from {
    display: flex;
  }
  to {
    display: none;
  }
}

.productsContainer {
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin: 0 20px;
  .populateSearch {
    padding: 10px;
    grid-column: 1/-1;
    display: flex;
    justify-content: center;
  }
}

.searchOption {
  background-color: #fff;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 10px;
}

.chat {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
  max-width: 1300px;
  min-width: 70%;
  padding-top: 30px;
}

@include up-to-normal-layout {
  .pageContainer {
    // background-color: #000000;
    &.app {
      padding-top: 45px;
    }

    .swiper {
      // background-color: #000000;
      // border: none;
    }
  }
  .chat {
    min-width: unset;
    max-width: unset;
    width: 100%;
    padding-top: unset;
  }
}
