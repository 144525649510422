
.account{
    padding: 12px 6px;
    border-radius: 3px;
    cursor: pointer;
    border: thin solid rgb(176, 167, 167);
    width: 250px;
    &:hover{
        background-color: #e4dfdf;
    }
    display: flex;
    gap: 8px;

}