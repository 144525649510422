@import "src/styles/index";

.autoCompleteList {
  padding: 14px;
}

.autoCompleteItem {
  padding: 10px 15px;
  cursor: pointer;
  transition: 0.1s;

  @include hover-supported {
    background-color: #f1efed;
  }
  &.focused {
    background-color: #f1efed;
  }
  &:last-of-type {
    border-bottom: none;
  }
}

@include for-normal-layout {
  .autoCompleteItem {
    color: #2d2d2d;
  }
}
