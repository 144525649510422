.msgContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 4px;
  width: 100%;

  &.user {
    align-items: flex-end;
  }
}

.msg {
  padding: 12px 16px;
  border-radius: 16px;
  max-width: 85%;
  background-color: #f5f5f5;
  font-size: 14px;
  line-height: 1.4;
}

.userMsg {
  background: linear-gradient(45deg, #4568dc, #b06ab3);
  color: white;
  padding: 12px 16px;
  border-radius: 16px;
  max-width: 85%;
  font-size: 14px;
  line-height: 1.4;
}

.chatSenderContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.productsMsgContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.productsContainer {
  display: flex;
  gap: 16px;
  width: 100%;
}

.chatProductContainer {
  animation: fadeSlideIn 0.5s ease-out forwards;
  opacity: 0;
  transform: translateY(20px);
  display: flex;
}
.chatProduct {
  width: 140px;
  max-width: 140px;

  img {
    object-fit: cover;
  }
}

@keyframes fadeSlideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.chatLayoutContainer {
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 0 16px;
}

.scrollableContent {
  flex: 1;
  overflow-y: auto;
  margin-top: 24px;
  padding-bottom: 180px; // Make space for the floating pills
  padding-top: 24px;
  // Add mask for top fade effect
  mask-image: linear-gradient(
    to bottom,
    transparent,
    black 20px,
    black calc(100% - 180px),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent,
    black 20px,
    black calc(100% - 180px),
    transparent
  );
}

.floatingPills {
  position: fixed;
  bottom: 0;

  left: 0;
  right: 0;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0)
  );
  padding: 24px 16px 42px 16px;
  padding-top: 40px;
  z-index: 10;
}
