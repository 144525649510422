@import "/src/styles/index.scss";
.product {
  display: flex;
  flex-direction: column;
  gap: 8px;
  transition: 0.2s;
  flex: 1;
  line-height: 1.05;
  animation-name: opacity;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-delay: 0.05;
  break-inside: avoid;
  page-break-inside: avoid;
  @include hover-supported {
    scale: 103%;
    cursor: pointer;
  }
  min-width: 140px;

  .imgPlaceholder {
    width: 100%;
    display: flex;
    flex: 1;
    height: max-content;
    aspect-ratio: 2/3;
    // background: #2d2d2d;
    border-radius: 6px;
    border: thin solid #80808014;
  }
  img {
    object-fit: cover;
    object-position: top;
    border-radius: 6px;
    aspect-ratio: 2/3;
  }
  .title {
    font-size: 12px;
    font-weight: 400;
    max-width: 23ch;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: capitalize;
  }

  .brandName,
  .price {
    font-size: 12px;
    line-height: 1.25;
    max-width: 100%;
  }

  &.sm {
    .brandName,
    .price {
      font-size: 12px;
    }
  }
  .price {
    font-weight: 500;
    &.prev {
      text-decoration: line-through;
    }
    &.current {
      color: var(--red-alert);
    }
  }
  .brandName {
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .vendorContainer {
    // line-height: 1.05;
    font-size: 13px;
    .vendor {
      font-weight: 400;
      color: #737373;
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
  .shopNow {
  }
}
.container {
  position: absolute;
  width: 100%;
  height: 100%;
  .expanderContainer {
    position: absolute;
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    div {
      opacity: 0;
      height: 0;
      max-width: 0%;
    }
  }
  &.expand {
    position: fixed;
    .expanderContainer {
      div {
        opacity: 1;
        height: fit-content;
        width: 100%;
        transition: opacity 1s;
      }
      img {
        height: 580px;
      }
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.details {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  gap: 2px;
}

.like {
  @extend .filterBg;
  padding: 8px;
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.filterBg {
  background-color: #ffffff7b;
  backdrop-filter: blur(30px);
}
