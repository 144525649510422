$padding-top-home: 48px;
.page {
  display: flex;
  flex-direction: column;
  // gap: 45px;
  padding: 15px;
  padding-bottom: 74px;
  min-height: 100vh;
}

.previewTitle {
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-editorial);
}

.iconsContainer {
  &.mobile {
    top: 45px;
  }
  z-index: 1;
  a {
    display: flex;
    align-items: center;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 15px;
  font-size: 24px;
  .icon {
    width: 24px;
    height: 24px;
    path {
      fill: white;
    }
  }
}

.contentContainer {
  background-color: white;
  border-radius: 15px;
}
.welcomeContainer {
  background: url("https://storage.googleapis.com/gigi_pics_public/Gradient.svg");
  display: flex;
  flex-direction: column;
  background-size: cover;
  width: 100%;
  // background-color: #e7e3f4;
}
.welcomeSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow: visible;
  padding: 60px;
}

.title {
  h2 {
    font-size: 28px;
    margin-bottom: 0px;
    font-weight: 700;
  }
  line-height: 2;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  .subtitle {
    font-size: 16px;
  }
  .gigi {
    font-weight: 600;
  }
}

.cardsContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.feed {
  padding: 32px 0px 96px 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.feedTitleContainer {
  @extend .sidePadding;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feedTitle {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #2d2d2d;
}

.feedIntroTitle {
  line-height: 2;
  font-size: 12px;
  font-weight: 400;
  color: #bababa;
  @extend .sidePadding;
}

.sidePadding {
  padding-inline-start: 16px;
  padding-inline-end: 16px;
}

.feedContentScroller {
  @extend .sidePadding;
  gap: 8px;
  display: flex;
  align-items: start;
  justify-content: baseline;
  overflow-x: auto;
}

.celebThumbnails {
  @extend .feedContentScroller;
  gap: 24px;
}

.celebrityAvatar {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  // width: 80px;
  align-self: baseline;
  .bgGradient {
    max-width: 84px;
    max-height: 84px;
    min-width: 84px;
    min-height: 84px;
    background: linear-gradient(
      147deg,
      rgba(206, 246, 141, 1) 0%,
      rgba(247, 132, 132, 1) 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 50%;
    .imgContainer {
      background-color: white;
      padding: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      img {
        object-position: top;
      }
    }
  }
  span {
    font-size: 12px;
  }
}

.brandBanner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  border-top: thin solid #dadadad9;
  border-bottom: thin solid #dadadad9;
  margin-bottom: 45px; // For Pagination bullets
}

.floatingBtn {
  padding: 8px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
}

.logo {
  font-size: 26px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.menu {
  display: flex;
  flex-direction: column;
  padding-top: 96px;
  padding-bottom: 32px;

  .item {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #1f2029;
      }
    }
    &.logout {
      color: #961c1c;
    }
  }
}
