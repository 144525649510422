.values {
  padding: 0 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  flex-wrap: wrap;
  transition: 0.25s;

  // max-height: 0px;
  // min-height: 0px;
  // &.open {
  //   max-height: 80px;
  //   min-height: 80px;
  // }
}
