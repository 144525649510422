.listContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow: auto;
  flex: 1;
  .listItem {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;

    .bullet {
      height: 24px;
      width: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      border: thin solid rgba(194, 192, 192, 0.668);
    }
  }
}
.tags {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: auto;
}
.tag {
  padding: 10px 15px;
  border-radius: 20px;
  background-color: rgb(229, 229, 229);
  font-size: 500;
  white-space: nowrap;
  &.selectedTag {
    background-color: black;
    color: white;
  }
}
