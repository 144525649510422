.selectedItemsContainer {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 20px;
  align-items: flex-end;
  padding: 20px;
}

.selectedItem {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: thin solid rgb(227, 224, 224);
  justify-content: center;
  align-items: center;
  position: relative;
  .removeItem {
    display: flex;
    justify-content: flex-end;
    visibility: hidden;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
  }
  &:hover {
    .removeItem {
      visibility: visible;
    }
  }
}
.searchContainer {
  min-width: 500px;
  max-width: 1000px;
  width: 70%;
  align-self: center;
}
.selectedItemContent {
  padding: 10px;
}
.recommended {
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    border-color: black;
  }
}
