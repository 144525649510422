.container {
}

.contentContainer {
  padding: 32px 26px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  transform: translateY(-50px);
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(40px);
}

.mainImg {
  display: flex;
  flex-direction: column;
  max-height: 450px;

  img {
    object-fit: cover;
    height: 100%;
    width: auto;
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 32px;
  line-height: 1.5;
}

.tabsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.tabs {
  display: flex;
  align-items: center;
  .tab {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 3px;
    align-items: center;
    font-weight: 600;
    gap: 6px;
    &::after {
      content: "";
      border-top: 3px solid transparent;
      border-radius: 50px;
      width: 100%;
    }
    &.selected {
      &::after {
        border-color: black;
      }
    }
  }
}

.lookbook {
  column-count: 2;
  column-gap: 10px;
  .item {
    display: inline-block;
    width: 100%;
    break-inside: avoid-column; /* Prevent items from breaking across columns */
    margin-bottom: 0;
    img {
      border-radius: 16px;
      width: 100%;
    }
  }
}
