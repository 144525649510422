.iconContainer {
  font-size: 20px;
}

.listPreview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  img {
    max-width: 80px;
    object-fit: cover;
    border-radius: 16px;
    aspect-ratio: 2/3;
  }
}
