.menu {
  display: flex;
  flex-direction: column;
  padding-top: 96px;
  padding-bottom: 32px;

  .item {
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #1f2029;
      }
    }
    &.logout {
      color: #961c1c;
    }
  }
}
