@import "src/styles/index";
.button {
  color: white;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0 20px;
  border-radius: 6px;
  font-size: 14px;
  white-space: nowrap;
  &.ai {
    background: linear-gradient(
      142deg,
      rgba(226, 232, 255, 1) 11%,
      rgba(229, 204, 254, 1) 42%,
      rgba(255, 213, 237, 1) 72%,
      rgba(255, 254, 215, 0.9990589985994398) 100%
    );
    color: var(--font-color-primary);
  }
  &.lg {
    height: 48px;
    min-height: 48px;
    max-height: 48px;
  }
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &.cta {
    $color: #e9ff99;
    background: $color;
    color: #1f2029;
    transition: 0.2s;
    flex: 1;
    @include hover-supported {
      background-color: lighten($color: $color, $amount: 0.2);
    }
  }
  &.primary {
    background-color: #1f2029;
    color: #ffffff;
    transition: 0.2s;

    @include hover-supported {
      // background-color: darken($color: white, $amount: 0.4);
    }
    &.disabled {
      background-color: #efefef;
      color: #626262;
    }
  }

  &.secondary {
    // border: thin solid aliceblue;
    border: thin solid #1f2029;
    color: #1f2029;
    background-color: white;
    &.disabled {
      // background-color: #e8e5e5;
      border: thin solid #c6c6c6;
      color: #c6c6c6;
    }
  }

  &.tertiary {
    padding: 0;
    height: unset;
    min-height: unset;
    max-height: unset;
    background-color: transparent;
    color: var(--font-color-secondary);
    font-size: 14px;
    transition: color 0.2s;
    &.disabled {
      color: #cccccc;
    }

    &.light {
      color: white;
    }
  }

  &.light {
    &.primary {
      color: black;
      background-color: white;
    }
    &.secondary {
      border: thin solid #2d2d2d;
      color: #2d2d2d;
      background-color: transparent;
      &.disabled {
        background-color: #626262;
        color: #e8e5e5;
      }
    }
  }
}
