.container {
  // border: thin solid rgb(208, 201, 201);
  border-radius: 6px;
  padding: 4px 14px;
  // height: 28px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: border-color 0.2s;
  width: 100%;
  // background-color: #efefef;
  // &:hover {
  //   border-color: #989797;
  // }
  // &.focused {
  //   border-color: #989797;
  // }
  input {
    &:disabled {
      opacity: 0.5;
    }
    // border-radius: 15px;
    border: none;
    outline: none;
    flex: 1;
    background-color: transparent;
    font-size: 16px;
    // font-size: 13px !important;
    color: aliceblue;
    &::placeholder {
      color: #6a6a6ad9;
      font-size: 14px;
    }
    &.light {
      color: #111111;
      &::placeholder {
        color: #5b5b5b;
      }
    }
  }
}
// .inputContainer{
//     position: relative;

// }
// .autoComplete{
// width: 100%;
// position: absolute;
// max-height: 300px;
// overflow: auto;
// left: 0;
// border: thin solid grey;
// border-top: none;
// }

// .autoCompleteItem{
// padding: 8px 5px;
// cursor: pointer;
// &:hover{
//     background-color: rgb(224, 218, 218);
// }
// border-bottom: thin solid grey;
// &:last-of-type{
//     border-bottom: none;
// }
// }

.icon {
  width: 20px;
  height: 20px;

  &.active {
    path {
      fill: #111111;
    }
  }
  path {
    fill: #737373;
  }
}
