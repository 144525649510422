.collection {
  // @extend .feedContentScroller;
  display: grid;
  gap: 0px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto auto;
  img {
    border-radius: 0px;
    aspect-ratio: 2/3;
    // max-width: 130px;
    object-fit: cover;
    // width: 120px;
  }
}
