.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  .details {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    .productsCount {
      font-weight: 300;
      color: rgb(175, 175, 175);
      text-align: center;
      margin: 0;
    }

    .description {
      font-weight: 300;
      font-size: 13px;
      text-align: center;
      line-height: 1.5;
      color: #737373;
    }
  }
}
.cta {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}
.products {
  padding-inline-start: 16px;

  gap: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
}

.title {
  font-size: 24px;
  font-weight: 500;
  font-family: var(--font-editorial);
}
