
.history{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 8px;
    min-width: 200px;
    background-color: rgb(0, 0, 0);
    color: rgb(217, 217, 227);
    padding: 14px 0.75rem ;
    .historyTitle{
        font-size: 12px;
        color: rgb(102,102,102);
        padding: 8px;
    }
    .chatInstance{
        padding: 8px;
        cursor: pointer;
        display: flex;
        align-items:  center;
         border-radius: 4px;
        gap: 10px;
        &:hover{
            background-color: #202123
        }
    }
}