//TODO: COLOR SCHEME

$cta-bg-color: #e9ff99;
// colors

// grey:
$grey1: #666;
$grey2: #a9a9a9;

//blue
$blue1: #0a0e3f;
$blue3: #0770cf;
$blue5: #00bfff;

$green: #53d769;

$gold1: #e3afbc;

$white: #ffffff;

$black: #333333;

$lavender: #b57edc;

$orange: #ff6b6b;

$gold2: #ffd700;

// ASOS
$lcxa5: #2d2d2d;
$fot7dv: #0000;
$pchel: #ffff;
$lq: #525050;
$f1xms: #666;
$c6hzt: #767676;
$na3s62: #ddd;
$wwppwc: #eee;
$sm859b: #f8f8f8;
$e93okh: #018849;
$cp6y8: #006637;
$eoeaa: #cceede;
$a5az0j: #ff9c32;
$fc982a: #b6752f;
$njx4kw: #ffebcc;
$cf22is: #0770cf;
$lzto1h: #1862a5;
$xes0jb: #cde2f5;
$xvrmqx: #d01345;
$j5f6ai: #fae7ec;
$mii22u: #ff3b30;

$mobile-breakpoint: 550px;
$narrow-breakpoint: 768px;
$normal-breakpoint: 1024px;
$wide-breakpoint: 1350px;

$font-primary-light: #191a1b;
$font-secondary-light: #737373;
$bg-primary-light: #ffffff;
$list-border-light: #a6a6a638;
$msg-light: #efefef;
$header-footer-passive: #808085;

$shadow-headers: 0px 4px 13px rgba(0, 0, 0, 0.06),
  0px 1px 2px rgba(0, 0, 0, 0.035);

$alert-red: #961c1c;

$color-lavender: #e8def7;
$color-pastel-beige: #f5e8c7;
$color-ivory: #f8f3e6;
$color-dusty-rose: #d1a7a0;

$font-editorial: "playfair";

:root {
  --font-editorial: "playfair";
  --red-alert: #961c1c;
  --font-color-primary: #2d2d2d;
  --font-color-secondary: #737373;
  --ai-gradient-bg: linear-gradient(
    142deg,
    rgba(226, 232, 255, 1) 11%,
    rgba(229, 204, 254, 1) 42%,
    rgba(255, 213, 237, 1) 72%,
    rgba(255, 254, 215, 0.9990589985994398) 100%
  );
}
