$padding-top-header: 0px;
.searchContainer {
  display: flex;
  flex-direction: column;
  // align-items: center;
  // max-height: 100%;
  // position: fixed;
  top: 0;
  width: 100vw;
  left: 0;
  padding-top: $padding-top-header;

  // &.app {
  //   padding-top: 42px;
  // }
  z-index: 1000;
  .back {
    width: 0px;
    overflow: hidden;
    transition: 0.6s;
    color: #1f2029;
    display: flex;
    justify-content: flex-start;
    &.active {
      // min-width: 40px;
      width: 40px;
    }
  }
  .logoHeader {
    background-color: #ffffff;
    transition: 0.6s;
    height: 42px;
  }
  .searchSuggestionsContainer {
    height: 0px;
    overflow: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #ffffff;
    z-index: 1;
  }
  &.full {
    height: 100vh;
    .logoHeader {
      height: 0px;
    }
    .back {
      width: 40px;
    }
    .searchSuggestionsContainer {
      height: 100%;
      animation-name: show;
      animation-duration: 0.6s;
      animation-fill-mode: forwards;
      // animation-delay: 0.2s;
      overflow: auto;
    }
  }
}

.inputContainer {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
    max-height: 0%;
  }
  to {
    opacity: 1;
    max-height: 100%;
  }
}
