@use '/src/styles/index.scss' as *;


.container{
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-bottom: 80px;
}
.stepsContainer{
    display: flex;
    margin-bottom: 15px;
}

.formBody{
    flex: 1;
    display: flex;
    flex-direction: column;

}
.navigation{
    display: flex;
    justify-content: space-between;
}


.snippetContainer{
    width: 1000px;
    align-self: center;
}