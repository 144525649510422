.itemContainer {
  padding: 10px;
  border-bottom: thin solid #a6a6a638;
  transition: 0.2s;
  &.selected {
    background-color: rgb(239, 239, 239);
  }
}
.itemTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itemContent {
  max-height: 0px;
  transition: 0.2s;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.4;
  &.open {
    max-height: 400px;
  }
}

.chevron {
  transition: 0.2s;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.open {
    transform: rotate(90deg);
  }
}

.item {
  .header {
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
  }
  font-size: 14px;
  .arrow {
    color: #797979;
    transition: 0.2s;
    transform: rotate(0deg);
  }
  .content {
    line-height: 1.5;
    transition: max-height 0.2s ease-in-out;
    max-height: 0px;
    overflow: hidden;
  }
  &.open {
    .arrow {
      transform: rotate(90deg);
    }
    .content {
      transition: max-height 0.2s ease-in-out;
      max-height: 400px;
    }
  }
}
