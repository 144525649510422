.welcome {
  background: linear-gradient(
    180deg,
    rgba(255, 231, 206, 1) 0%,
    rgba(254, 230, 232, 1) 22%,
    rgba(245, 204, 207, 1) 88%
  );
}
.app {
  width: 100dvw;
  height: 100dvh;
  // overflow: auto;
  // background: url("./styles/icons/AppBackground.svg");
  // background-size: cover;
  // background-repeat: no-repeat;
  // &.mobileApp {
  //   padding-top: 45px;
  // }
  display: flex;
  flex-direction: column;
}

.appLoader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  z-index: 1000000;
  display: flex;
  background-color: white;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  &.disappear {
    animation-name: disappear;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
  }
  &.none {
    display: none;
  }
}

.moveLeft {
  transform: translateX(-25%);
}

@keyframes disappear {
  from {
    transform: translateY(0%);
    // opacity: 1;
  }
  to {
    transform: translateY(-100%);
    // opacity: 0;
  }
}

.desktopMsg {
  padding: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #f5f5f5a3;
  border-radius: 15px;
}

// Custom Libraries
.swiper {
  --swiper-pagination-color: black;
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(0, 0, 0, 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}
