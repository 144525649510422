.typingDisplay {
  display: flex;
  flex: 1;
  width: 100%;
  flex-wrap: wrap;
  word-wrap: break-word;
  font-size: inherit;
  font-weight: inherit;
}

.blinker {
  border-left: 4px solid #1f2029;
  height: 100%;
  display: flex;
  animation: blink;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  height: 20px;
  &.show {
    visibility: visible;
    animation: unset;
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
