.chatBtn {
  position: fixed;
  transition: 0.2s;
  bottom: 75px;
  right: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  font-size: 20px;
  background-color: #ff2f40;
  // width: min-content;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  color: white;
  // gap: 10px;
  padding: 14px 20px;
  z-index: 10;
  &.appLayout {
    bottom: 95px;
  }
  span {
    transition: 0.2s;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    max-width: 200px;
  }
  &.mini {
    padding: 14px;
    span {
      max-width: 0px;
    }
  }
}
